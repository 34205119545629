import { Form } from "vee-validate";
import { string, object, boolean } from "yup";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isNil } from "lodash";
import Cookies from "js-cookie";
import { generateXeroToken } from "@/pages/shared/services/xero";
export default {
  components: {
    Form
  },
  setup() {
    const API = inject("axios");
    const toast = inject("toast");
    const loading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const returnUrl = ref(null);
    const showPass = ref(false);
    const tfa = ref(null);
    const tfa_code = ref(null);
    const tfaThirtyDays = ref(false);
    const resendAccount = ref(null);
    const form = ref({
      email: null,
      password: null,
      forgotPassword: false
    });
    const schema = object({
      forgotPassword: boolean().default(false),
      email: string().email("Invalid email address").required().label("Email Address").nullable(),
      password: string().when("forgotPassword", {
        is: false,
        then: string().required().label().nullable(),
        otherwise: string().nullable()
      })
    });
    const rememberMe = ref(false);
    if (route.params?.name == "forgot-password") form.value.forgotPassword = true;
    if (route.query?.email) form.value.email = route.query.email;
    const onSubmit = () => {
      loading.value = true;
      API.post("/auth/login", {
        ...form.value,
        rememberMe: rememberMe.value
      }).then(({
        data
      }) => {
        loading.value = false;
        let loginURL = new URL(process.env.VUE_APP_APP_URL);
        loginURL.searchParams.set("access", data.token);
        if (returnUrl.value) loginURL.searchParams.set("return", returnUrl.value);
        if (isNil(data.tfa)) {
          if (rememberMe.value) Cookies.set("access", data.token, {
            expires: 30,
            secure: true,
            domain: process.env.VUE_APP_COOKIE_DOMAIN
          });
          window.location.href = loginURL.href;
        } else {
          tfa.value = data.tfa;
          console.log(rememberMe.value);
        }
      }).catch(err => {
        if (err.response?.data?.msg) {
          if (err.response?.data?.account) resendAccount.value = err.response.data;else toast({
            text: err.response.data.msg,
            type: "error"
          });
        } else toast({
          text: "Something went wrong",
          type: "error"
        });
        loading.value = false;
      }).finally(() => {
        loading.value = false;
      });
    };
    const onSubmitTFA = () => {
      if (!tfa_code.value) {
        toast({
          text: "Please enter your TFA code",
          type: "error"
        });
        return;
      }
      loading.value = true;
      API.post("/auth/2fa/validate", {
        tfa: tfa.value,
        code: tfa_code.value,
        thirtyDays: tfaThirtyDays.value
      }).then(({
        data
      }) => {
        tfa_code.value = null;
        loading.value = false;
        let loginURL = new URL(process.env.VUE_APP_APP_URL);
        loginURL.searchParams.set("access", data.token);
        if (returnUrl.value) loginURL.searchParams.set("return", returnUrl.value);
        if (rememberMe.value) Cookies.set("access", data.token, {
          expires: 30,
          secure: true,
          domain: process.env.VUE_APP_COOKIE_DOMAIN
        });
        window.location.href = loginURL.href;
      }).catch(err => {
        toast({
          text: err.response.data.msg,
          type: "error"
        });
        loading.value = false;
      });
    };
    const onSubmitFP = resetForm => {
      loading.value = true;
      API.post("/users/account/forgot-password", form.value).then(() => {
        loading.value = false;
        form.value.forgotPassword = false;
        router.push({
          path: "/"
        });
        toast({
          text: `Password reset link sent to your email: <strong>${form.value.email}</strong>`,
          type: "success"
        });
        resetForm();
      }).catch(err => {
        if (err.response?.data?.msg) toast({
          text: err.response.data.msg,
          type: "error"
        });
        loading.value = false;
      }).finally(() => {
        loading.value = false;
      });
    };
    const toggleShowPass = () => {
      showPass.value = !showPass.value;
    };
    if (route.params?.name == "forgot-password") form.value.forgotPassword = true;

    // OAuth2 pkce flow
    const xeroAuthURL = generateXeroToken("/login/xero", {}, true);
    const otp = ref(null);
    onMounted(() => {
      document.title = "Login";
      const inputs = document.querySelectorAll("#otp > *[id]");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("keydown", function (event) {
          if (event.key === "Backspace") {
            inputs[i].value = "";
            if (i !== 0) inputs[i - 1].focus();
          } else {
            if (i === inputs.length - 1 && inputs[i].value !== "") {
              return true;
            } else if (event.keyCode > 47 && event.keyCode < 58) {
              inputs[i].value = event.key;
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            } else if (event.keyCode > 64 && event.keyCode < 91) {
              inputs[i].value = String.fromCharCode(event.keyCode);
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            }
          }
        });
      }
    });
    const resendVerificationEmail = () => {
      resendAccount.value["loading"] = true;
      API.post("/auth/confirm/resend", {
        ...resendAccount.value
      }).then(() => {
        toast({
          text: "Verification email re-sent successfully"
        });
        resendAccount.value = null;
      }).catch(err => {
        toast({
          text: "There was an error re-sending the verification email",
          type: "error"
        });
        console.error(err);
      }).finally(() => {
        resendAccount.value["loading"] = false;
      });
    };
    return {
      returnUrl,
      showPass,
      loading,
      onSubmit,
      toggleShowPass,
      rememberMe,
      onSubmitFP,
      xeroAuthURL,
      otp,
      tfa,
      tfa_code,
      onSubmitTFA,
      tfaThirtyDays,
      resendAccount,
      resendVerificationEmail,
      schema,
      form
    };
  },
  created() {
    if (this.$route.query?.error) {
      if (this.$route.query?.error == "Plan cancelled") {
        this.$toast({
          text: "Subscription Plan has been cancelled. Please contact support",
          type: "error"
        });
      } else {
        this.$toast({
          text: this.$route.query?.error,
          type: "error"
        });
      }
    } else if (this.$route.query?.msg) this.$toast({
      text: this.$route.query?.msg
    });
    if (this.$route.query?.return) {
      this.returnUrl = this.$route.query?.return;
      this.$router.replace(this.$route.path + (this.returnUrl ? "?return=" + this.returnUrl : ""));
    }
  }
};